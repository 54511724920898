.fertility_after_birth {
  .nav-buttons-container {
    &.optional-link {
      margin-top: 0;
      padding-top: 12px;
      justify-content: center;

      img {
        display: none;
        width: 200px;
        
        @media only screen and (min-width: 719px){
          display: block;
          margin-right: 30px;
        }

        @media only screen and (min-width: 1000px){
          width: 250px;
        }
      }

      .flex-group {
        display: flex;
        flex-direction: column;
        align-items: center;

        .nav-button {
          width: 280px;
          padding: 0 12px;
        }
      }
    }
  }
}
