@import '../../scss/colors';

#inactivity-modal {
  font-size: 20px;
  color: $dark_gray;
  z-index: 5000;
  
  .modal {
    padding: 20px;
  }
}

@media only screen and (max-width: 719px) {
  #inactivity-modal div { width: 90% }
}