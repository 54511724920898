@import '../../../scss/colors';
@import '../../../scss/mixins';
@import '../../../scss/dimensions';

.background-image.side_effects {
  .flex-group {

    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: flex-start;
    justify-content: flex-start;
  }

  >.markdown-block p:last-of-type { margin-bottom: 15px; }

  .mobile { display: none }
  .regular {
    height: 100%;
    width: 100%;
  }

    .side-effects-navigation-container {
      width: 100%;
      // order: 5;
      margin-top: auto;

      .mobile .nav-buttons-container.fake {
        -webkit-flex-direction: row;
        flex-direction: row;
      }
    }

    .category-bar-container {
      margin-top: 20px;
      width: 100%;

      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: center;
      -webkit-box-align: center;
      align-items: center;
      box-sizing: border-box;
      height: 100px;

      $category-bar-width: 600px;

      .category-bar {
        width: 100%;
        max-width: $category-bar-width;

        display: -webkit-flex;
        display: flex;
        -webkit-justify-content: center;
        justify-content: center;

        button {
          font-family: "Archer SSm A", "Archer SSm B";
          font-weight: 600;
          box-sizing: border-box;
          -webkit-flex: 1 0 auto;
          flex: 1 0 auto;
          height: 50px;
          border-right: 1px dotted white;
          font-size: 0.95em;
          line-height: 1.75;
          background-color: #BFBFBF;
          margin: 0;
          display: flex;
          justify-content: center;
          align-items: center;

          &.selected {
            background-image: url('../../../shared_images/hatched-button-overlay-tablet@2x.png');
            font-style: italic;
            background-size: 600px auto;

            &:nth-of-type(1) { background-color: $orange; }
            &:nth-of-type(2) { background-color: $light_green; }
            &:nth-of-type(3) { background-color: $light_blue; }
          }

          &:last-of-type { border-right: none; }
        }
      }

      #category-intro-text{
        background-color: $dark-blue;
        padding: 7px 9px;
        color: white;
        width: 100%;
        max-width: $category-bar-width;
        box-sizing: border-box;
        line-height: 1.4;

        p {   font-size: 14px; }
      }
    }

    .icon-column-container {
      background-image: url('../../../shared_images/silhouettes/female-silhouette-large.png');
      background-repeat: no-repeat;
      background-position: center;
      // background-color: pink;

      .icon-and-map-container {
        .icon-button{

          &::after {
            height: 100%;
            width: 100%;
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
            background-size: cover;
          }
          &.dim::after {opacity: 0.6}
        }

        .map.right {background-position: right;}
      }
    }

    &.es {
      .category-bar-container .category-bar .selection-button {
        width: auto;
        &:nth-of-type(1), &:nth-last-of-type(2) {
          -webkit-flex-grow: 1;
          flex-grow: 1;
        }
      }
    }


  .icon-column-container.benefits {
    .icon-and-map-container {
      .icon-button { margin: 30px 0;}

      .map {
        &.bottom { bottom: 68px;}
        &.top { top: 68px;}
      }




      &.decreasedAcne {
        .icon-button {
          &::after { background-image: url('./images/acne-OFF.png');}
          &.color::after { background-image: url('./images/acne-ON.png')};
        }

        .map {
          background-image: url('./images/good-acne-map.png');
          height: 72px;
        }
      }

      &.cancerPrevention {
        .icon-button {
          &::after { background-image: url('./images/cancer-OFF.png');}
          &.color::after { background-image: url('./images/cancer-ON.png')};
        }
        .map {
          background-image: url('./images/good-cancer-prevention-map.png');
          height: 10px;
        }
      }

      &.decreasedCramping{
        .icon-button {
          &::after { background-image: url('./images/less-cramping-OFF.png');}
          &.color::after { background-image: url('./images/less-cramping-ON.png')};
        }
        .map {
          background-image: url('./images/good-less-cramping-map.png');
          height: 135px;
        }
      }

      &.noPeriod {
        .icon-button {
          &::after { background-image: url('./images/lack-of-bleeding-OFF.png');}
          &.color::after { background-image: url('./images/lack-of-bleeding-ON.png')};
        }
        .map.top {
          background-image: url('./images/good-lack-of-bleeding-map.png');
          height: 80px;
        }
      }

      &.decreasedPeriod {
        .icon-button {
          &::after { background-image: url('./images/lighter-periods-OFF.png');}
          &.color::after { background-image: url('./images/lighter-periods-ON.png')};
        }
        .map.bottom {
          background-image: url('./images/good-lighter-period-map.png');
          height: 64px;
        }
      }

      .info-box {
        &.decreasedAcne { top: 39px;}
        &.cancerPrevention {top: 88px; }
        &.decreasedCramping {bottom: 213px; }
        &.noPeriod {top: 158px;}
        &.decreasedPeriod {bottom: 142px; }
      }
    }
  }


  .icon-column-container.side-effects {
    .icon-and-map-container {
      .icon-button { margin: 20px 0;}

      .map {
        &.bottom { bottom: 58px;}
        &.top { top: 58px;}
      }

    }

    .depression {
      .icon-button {
        &::after { background-image: url('./images/depression-OFF.png');}
        &.color::after { background-image: url('./images/depression-ON.png')};
      }

      .map.bottom {
        background-image: url('./images/annoying-depression-map.png');
        height: 59px;
      }
    }

    .decreasedInterestInSex {
      .icon-button {
        &::after { background-image: url('./images/sex-OFF.png');}
        &.color::after { background-image: url('./images/sex-ON.png')};
      }

      .map.bottom {
        background-image: url('./images/annoying-sex-map.png');
        height: 180px;
      }
    }

    .cramping {
      .icon-button {
        &::after { background-image: url('./images/cramping-OFF.png');}
        &.color::after { background-image: url('./images/cramping-ON.png')};
      }

      .map.bottom {
        background-image: url('./images/annoying-cramping-map.png');
        height: 54px;
      }
    }

    .weightGain {
      .icon-button {
        &::after { background-image: url('./images/weight-gain-OFF.png');}
        &.color::after { background-image: url('./images/weight-gain-ON.png')};
      }

      .map.bottom {
        background-image: url('./images/annoying-weight-gain-map.png');
        height: 232px;
      }
    }

    .noPeriod {
      .icon-button {
        &::after { background-image: url('./images/lack-of-bleeding-OFF.png');}
        &.color::after { background-image: url('./images/lack-of-bleeding-ON.png')};
      }

      .map.top {
        background-image: url('./images/annoying-lack-of-bleeding-map.png');
        height: 150px;
        width: 220px;
        background-size: cover;
        background-position: right top;
      }
    }

    .spotting {
      .icon-button {
        &::after { background-image: url('./images/spotting-OFF.png');}
        &.color::after { background-image: url('./images/spotting-ON.png')};
      }

      .map.top {
        background-image: url('./images/annoying-spotting-map.png');
        height: 27px;
      }
    }

    .heavierPeriod {
      .icon-button {
        &::after { background-image: url('./images/heavy-bleeding-OFF.png');}
        &.color::after { background-image: url('./images/heavy-bleeding-ON.png')};
      }

      .map.bottom {
        background-image: url('./images/annoying-heavy-bleeding-map.png');
        height: 118px;
      }
    }

    .info-box {
      &.depression {
        top: 13px;
      }

      &.decreasedInterestInSex {
        bottom: 0px;
      }

      &.cramping {
        bottom: 125px;
      }

      &.weightGain {
        bottom: 299px;
      }

      &.noPeriod {
        top: 215px;
      }

      &.spotting {
        bottom: 45px;
      }

      &.heavierPeriod {
        bottom: 183px;
      }
    }
  }


  .icon-column-container.other-considerations {
    padding: 50px 0 ;

    .icon-and-map-container {
      .icon-button { margin: 30px 0 }
      .map {
        &.bottom { bottom: 68px;}
        &.top { top: 68px;}
      }
    }

    .depression {
      .icon-button {
        &::after { background-image: url('./images/depression-OFF.png');}
        &.color::after { background-image: url('./images/depression-ON.png')};
      }
      .map.bottom {
        background-image: url('./images/worry-depression-map.png');
        height: 169px;
      }
    }

    .nausea {
      .icon-button {
        &::after { background-image: url('./images/nausea-OFF.png');}
        &.color::after { background-image: url('./images/nausea-ON.png')};
      }
      .map.bottom {
        background-image: url('./images/worry-nausea-map.png');
        height: 122px;
      }
    }

    .breastTenderness {
      .icon-button {
        &::after { background-image: url('./images/cramping-OFF.png');}
        &.color::after { background-image: url('./images/cramping-ON.png')};
      }

      .map.bottom {
        background-image: url('./images/worry-tenderness-map.png');
        height: 55px;
      }
    }

    .weightGain {
      .icon-button {
        &::after { background-image: url('./images/weight-gain-OFF.png');}
        &.color::after { background-image: url('./images/weight-gain-ON.png')};
      }

      .map.bottom {
        background-image: url('./images/worry-weight-gain-map.png');
        height: 122px;
      }
    }

    .info-box {
      &.depression {
        bottom: -90px;
      }

      &.nausea {
        bottom: 199px;
      }

      &.breastTenderness {
        top: 25px;
      }

      &.weightGain {
        bottom: 198px;
      }
    }
  }
}

@media only screen and (min-width: 835px){
  .background-image.side_effects {
    .flexbox-group .category-bar-container { margin-top: 23px; }

    .icon-column-container { margin-top: 21px;}
  }
}

@media only screen and (max-width: 579px){
  .background-image.side_effects {
    .regular { display: none; }
    .mobile { display: block }
    .mobile-no-display { display: none !important }

    .mobile-intro-overlay {

      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-align-items: center;
      -webkit-box-align: center;
      align-items: center;

      &.no-display { display: none; }

      .category-bar {

        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-justify-content: space-between;
        justify-content: space-between;
        height: 270px;
        width: 90%;
        padding: 15px 0 30px 0;

        .selection-button {
          height: 70px;
          color: $dark_gray;
          @include archer;
          text-transform: none;
          font-size: 0.9375em;

          &.visited {
            background-size: cover;
            background-image: url('../../../shared_images/hatched-button-overlay-tablet@2x.png');
          }

          &:nth-child(1) {
            background-color: $orange;
          }
          &:nth-child(2) {
            background-color: $lighter_green;
          }
          &:nth-child(3) {
            background-color: $light_blue;
          }
        }
      }
    }

    .mobile-category-page-top {
      min-height: 150px;

      .markdown-block p {  margin-bottom: 0; }
    }

    .icon-column-container {
      .icon-and-map-container {
        .map { width: 118px; }
        .info-box-up-arrow, .info-box-down-arrow { left: 75px;}
      }


    &.benefits {

      .icon-and-map-container {
        .icon-button { margin: 18px 0; }

        .map {
          &.bottom { bottom: 20px;}
          &.top { top: 40px;}
        }


        &.decreasedAcne {
          .info-box { top : 32px; }
        }

        &.cancerPrevention {
          .info-box {
            top: auto;
            bottom : 55px;

            .info-box-down-arrow {visibility: visible}
            .info-box-up-arrow { visibility: hidden;}
          }
        }

        &.decreasedCramping {
          .map { height: 110px;}
          .info-box { bottom : 137px; }
        }

        &.noPeriod {
          .map.top { height: 53px;}
          .info-box {
            top: auto;
            bottom: 0;
            .info-box-down-arrow {visibility: visible}
            .info-box-up-arrow { visibility: hidden;}
          }
        }

        &.decreasedPeriod {
          .map.bottom { height: 88px;}
          .info-box { bottom : 92px; }
        }
      }

    }

    &.side-effects {
      .icon-and-map-container {
        .icon-button { margin: 12px 0; }

        .map {
          &.bottom { bottom: 37px;}
          &.top { top: 40px;}
          &.left { left: 37px; }
          &.right { right: 52px;}
        }
      }

      .depression {
        .map.bottom { height: 36px;}
        .info-box { top : 31px; }
      }

      .decreasedInterestInSex {
        .map.bottom { height: 110px;}
        .info-box {  bottom: -45px; }
      }

      .cramping {
        .map.bottom { height: 34px;}
        .info-box {  bottom: 84px; }
      }

      .weightGain {
        .map.bottom { height: 143px;}
        .info-box {  bottom: 190px; }
      }

      .noPeriod {
        .map.top {
          height: 80px;
          width: 118px;
        }

        .info-box {
          top: auto;
          bottom: -30px;

          .info-box-down-arrow {visibility: visible}
          .info-box-up-arrow { visibility: hidden;}
        }

      }

      .spotting {
        .map.top { height: 14px;}
        .info-box {  bottom: 25px; }
      }

      .heavierPeriod {
        .map.bottom { height: 64px;}
        .info-box {  bottom: 117px; }
      }
    }

    &.other-considerations {
      .icon-and-map-container {
        .icon-button { margin: 18px 0; }

        .map {
          &.bottom { bottom: 43px;}
          &.top { top: 40px;}
          &.left { left: 37px; }
          &.right { right: 52px;}
        }
      }

      .depression {
        .map.bottom { height: 102px;}
        .info-box {
          top: -40px;
          bottom: auto;
        }
      }

      .nausea {
        .map.bottom { height: 74px;}
        .info-box {  bottom: 127px }
      }

      .breastTenderness {
        .map.bottom { height: 30px;}
        .info-box {  top: 36px }
      }

      .weightGain {
        .map.bottom { height: 65px;}
        .info-box {  bottom: 110px }
      }
    }
  }
  }
}
