@use "sass:math";

@import '../../../scss/colors';
@import '../../../scss/mixins';

.intro.background-image {
  background-image: url('./big-deal-50T-background.jpg');
  display: -webkit-flex; 
  display: flex;
  width: auto;
  margin: 0;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;

  .aria {
    
    display: -webkit-flex; 
    display: flex;
    -webkit-flex-direction: row;
    flex-direction: row;

    .flex-group{
      
      display: -webkit-flex; 
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;

      .heading-container {
        padding: 28px 0 20px 0; 
        
        h1{ 
          background-color: $dark-blue;
          color: white;
          margin-bottom: 9px;
          padding: 0 8px;
          display: inline-block;
          line-height: 1.575;
          // white-space: pre-wrap;
          box-decoration-break: clone;

          &:last-of-type { margin-bottom: 0 }
        }
      }

      .nav-buttons-container {
        position: initial;
        width: 100%;
        margin: 4px 0 0 0;
      }
    }
  }

  .overlay {
    .modal {
      padding: 20px 20px 0; 

      h1 {
        @include archer;
        font-size: 24px;
        margin-bottom: 10px;
      }

      .modal-content { 
        width: 100%;       
      }
    }
  }
  
    //Spanish styling 
  &.es {
    .aria .flex-group .heading-container {
      margin-bottom: 20px;

      h1{
        font-size: 22px;
        margin-bottom: 5px;
        padding: 0;
      }
    }
  }
} 

@media only screen and (min-width: 835px){
  %spacer {
    content: '';
    height: 500px;
    width: 1px;
  }

  .intro.background-image {
    background-image: url('./big-deal-50DM-background.jpg');
    background-position: right center;
    align-items: stretch;
    padding: 0;

    .aria { 
      margin: 0;
      width: 100%;
      &:before {
        @extend %spacer;
        -webkit-flex: 1;
        flex: 1;
      }
      &:after  {
        @extend %spacer;
        -webkit-flex: 2.3;
        flex: 2.3;
      }

      .flex-group { max-width: 688px;}

      .heading-container { 
        padding-top: 25px; 

        h1{font-size: 1.875em;}
      }
    }
  }
}

@media only screen and (max-width: 719px){
  .intro.background-image {
    background-size: contain;
    background-image: url('./big-deal-50DM-background.jpg');
    width: auto;

    .aria {
      width: 100%;
      margin: 0;

      .flex-group {
        .heading-container {
          min-height: math.div(1, 1.76) * 100vw;
          padding-top: 16px;
          box-sizing: border-box;
          
          h1{ 
            line-height: 1.8;
            margin-bottom: 8px;
            font-size: 3.9vw;
          }
        }
        
        .markdown-block {
          margin: 20px 0 3px 0;
          
          p {
            @include mobile-paragraph-style;
            margin-bottom: 6.75%;
          }
        }
        
        .nav-buttons-container {
          padding: 0 0 30px;
        }
      }
    }

    .overlay .modal {
      @include mobile-modal-style;
    }

    &.es {
      .aria {
        .heading-container h1{
          display: inline;

          &:after { 
            content: '';
            display: block;
            height: 8px;
            width: 90vw;
          }
        }

        .markdown-block { margin-top: 10px; }
      }
    }
  }

  #intro-footer { display: none; }
}
