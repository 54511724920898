.mbc-footer-mp {
  box-sizing: border-box;
  width: 90vw;
  max-width: 688px;
  height: 100px;
  
  display: -webkit-flex; 
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0;
  justify-self: flex-end;
  background-color: transparent;
  // margin-bottom: 20px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;

  &.path4you {
    max-width: 800px;

    img { height: 60%; }
  }

  .uw, .ucsf {
    height: 70%;
    position: relative;
    bottom: 6px;
    margin-right: 0;
  }

  .va {
    height: 100%;
  }
}

@media only screen and (min-width: 835px){
  .mbc-footer-mp { margin-bottom: 25px;}
}

@media only screen and (max-width: 719px){
  .mbc-footer-mp { 
    height: 15vw;
    width: 95vw;
  }
}

