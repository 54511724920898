@import '../../../scss/colors';
@import '../../../scss/mixins';

.intro_mp {
  background-image: url('../../../shared_images/splash-mp-new-background.jpg');
  -webkit-justify-content: flex-end;
  justify-content: flex-end;
  
  .aria {
    color: white;
    
    display: -webkit-flex; 
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    -webkit-flex: 1 0 auto;
    flex: 1 0 auto;
    
    .flex-group {
      background-color: rgba(0, 0, 0, 0.6);
      border-radius: 10px;
      padding: 30px;
      width: 640px;
      box-sizing: border-box;
      -webkit-flex: 0 0 auto;
      flex: 0 0 auto;
      
      .heading-container {
        padding-top: 0;
        padding-bottom: 10px;

        h1{ color: white; }
      }

      .markdown-block {
        ul { 
          list-style-type: disc;
          font-size: 1.25em;
          line-height: 1.5;
          padding-left: 31px;
        }
      }
    }
  }

  .overlay .modal { 
    padding: 20px 20px 0px;

    h1 {
      @include archer;
      font-size: 24px;
      margin-bottom: 10px;
    }

  }
}

@media only screen and (min-width: 835px){
  .intro_mp .flex-group .heading-container { padding-top: 0;}
}

@media only screen and (max-width: 719px){
  .intro_mp {
    width: 100vw;
    
    .nav-buttons-container{
      &::after {
        background-color: rgba(0, 0, 0, 0.6);
        height: 100%;
        width: 200%;
        content: "";
        position: absolute;
        top: 0;
        left: -50%;
        z-index: 5;
      }

      .nav-button { z-index: 15;}
    }

    .aria {
      -webkit-justify-content: flex-start;
      justify-content: flex-start;
      margin-top: 0;

      .flex-group{
        width: 100vw;
        border-radius: 0;
        position: static;
        padding: 14px 5vw 0 5vw;
        height: 100%;
        -webkit-flex-grow: 1;
        flex-grow: 1;

        .heading-container { padding-bottom: 12px;  }

        .markdown-block {
          p, ul { font-size: 16px;}

          p { margin-bottom: 20px; }

          ul li {
            line-height: 1.2;
            margin-bottom: 2px;
          }
        }
      }
    }

    .overlay .modal {
      @include mobile-modal-style;
    }
  }
}
