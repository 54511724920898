@import '../../../scss/colors';
@import '../../../scss/mixins';

.menu-item {
  @include archer;
  font-style: italic;
  font-size: 20px;
  line-height: 1.5;
  padding: 20px;
  color: white;
  position: relative;
  text-transform: none;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;

  &.first { background: $dark_blue;}
  &.second { background: $medium_blue;}
  &.third { background: $light_green;}
  &.fourth { background: $dark_orange;}
  &.fifth { background: $dark_green; }
  &.sixth { background: $orange; }
  &.visited {
    background-image: url('./overlay@2x.png');
    background-size: cover;
  }

  .view-answer {
    border: 1px solid #fff;
    color: #fff;
    padding: 10px;
    width: 180px;
    background: transparent;
    border-radius: 0;
    font-size: 24px;
    line-height: 20px;
    height: 45px;
    text-align: center;
    font-family: Georgia;
    font-style: italic;
    position: relative;
    box-sizing: border-box;
    font-weight: normal;

    .menu-item-check {
      position: absolute;
      height: 24px;
      width: 30px;
      right: 50%;
      bottom: 50%;
      transform: translate(50%, 50%)
    }
  }
}

.overlay .menu-item-modal {
  width: 640px;
  max-width: 100vw;
  padding: 20px;
  .menu-item-modal-copy .markdown-block h1 { @include modal-green-title; }
}

.is-mypath .menu-item {
  font-weight: 500;
}

// 508
.accessible-colors .menu-container .menu-item {
  background-color: white;
  color: $dark_gray;
  border: 1px solid $dark_gray;
  padding: 20px 20px 20px 30px;

  &.first { background: linear-gradient(90deg, $orange 20px, #FFFFFF 20px);}
  &.second { background: linear-gradient(90deg, $dark_orange 20px, #FFFFFF 20px);}
  &.third { background: linear-gradient(90deg, $light_green 20px, #FFFFFF 20px);}
  &.fourth { background: linear-gradient(90deg, $dark_green 20px, #FFFFFF 20px);}
  &.fifth { background: linear-gradient(90deg, $medium_blue 20px, #FFFFFF 20px);}
  &.sixth { background: linear-gradient(90deg, $dark_blue 20px, #FFFFFF 20px);}

  &.visited {
    &.first { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $orange 20px, #FFFFFF 20px);}
    &.second { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $dark_orange 20px, #FFFFFF 20px);}
    &.third { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $light_green 20px, #FFFFFF 20px);}
    &.fourth { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $dark_green 20px, #FFFFFF 20px);}
    &.fifth { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $medium_blue 20px, #FFFFFF 20px);}
    &.sixth { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $dark_blue 20px, #FFFFFF 20px);}
  }

  .view-answer { background: $link_green; }
}
