@import '../../../scss/colors';
@import '../../../scss/mixins';

@mixin font_regular {
  font-size: 13px;
  line-height: 1.2;
}

@mixin font_h2 {
  font-size: 13px;
  font-style: italic;
  font-weight: normal;
  line-height: 1.5;
  color: #ffffff;
}

@mixin font_left_th {
  font-style: italic;
  font-size: 13px;
  color: #006477;
  font-weight: normal;
}

@mixin cell_padding {
  padding: 5px 10px;
}

.summary {
  position: relative;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;

  .print-only {
    display: none;

    @media print { display: block; }
  }

  .mybc-logo { margin: 0 auto 0 0; }

  .print-button {
    &.regular {
      background-color: transparent;
      width: 194px;
      background-image: url('./print-ribbon.png');
      padding-left: 40px;
      padding-bottom: 20px;
    }
    &.mobile {
      background-color: $medium_blue;
      width: 100%;
      font-size: 20px;
      line-height: 1;
      color: white;
      display: none;
      background-image: none;
      position: initial;
      height: 40px;
      margin-bottom: 20px;

      p, img { display: inline; }

      p {
        position: relative;
        bottom: 5px;
        left: 10px;
      }
    }
  }

  .bc-placeholder { margin-bottom: 20px; }
  .summary-heading {
    width: 100%;
    display:-webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;

    .user-id {
      font-size: 24px;
      font-style: italic;
      padding: 24px 0 20px;
      margin-left: 5px;
      line-height: 1.3;
      display: none;
      white-space: pre;
    }
  }

  .hidden {
    width:1px;
    height:1px;
    overflow:hidden;
    display: block;
  }

  .description, #mypath_description, #mypath_postscript, .path4you-study-info {
    @include font_regular;
    font-size: 20px;
    line-height: 1.3;
    margin-bottom: 20px;

    strong {
      font-size: 18px;
      font-weight: bold;
    }

  }

  #mypath_description, #mypath_postscript {
    ol ul { margin: 5px 0; }

    ol {
      list-style: decimal;
      padding-inline-start: 40px;
    }

    a {
      text-decoration: underline 1px $medium_blue;
      color: $medium_blue;
    }

    p { margin-bottom: 4px; }
  }

  .path4you-study-info {
    margin: 16px 0;

    .study-id-mini {
      margin: 0 auto;
      width: 280px;

      h1 {
        max-width: 200px;
        width: 200px;
      }
    }
  }

  .summary-container, .HelpfulResources {
    max-width: 691px;
    margin: 0px auto;
    width: 100%;
  }

  .HelpfulResources ul {
    padding: 10px;

    li p {
      font-size: 13px;
      line-height: 1.2;
    }
  }

  .item {
    border: 1px solid #006477;
    margin-bottom: 20px;
    // clear: both;
    page-break-inside: avoid;

    h2 {
      @include font_h2;
      background: #006477;
      margin: 0;
      padding: 3px 10px;
    }

    ul {
      page-break-inside: avoid;
    }

    .empty {
      padding: 10px 10px 10px 0;
      margin: 0;
      font-size: 13px;
      line-height: 19px;
      font-style: italic;
      color: #404040;
    }
  }

  .item.topics .empty { padding: 10px;}

  .item.empty-table {
    margin-bottom: 10px;

    th.screen-reader-only {
      font-size: 1px;
      color: transparent;
      background: none;
      border: 0;
    }

    td, td:first-child {
      background: none;
      border: none;
      color: #404040;
      padding-left: 0;
    }
  }

  .item.check-table td img { vertical-align: middle; }

  .item.questions {
    border-bottom: none;

    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      display: table;
      width: 100%;

      li {
        // @include clearfix;
        border-bottom: 1px solid #006477;
        display: table-row;
        border-collapse: collapse;

        .question, .answer {
          width: 50%;
          font-size: 13px;
          line-height: 23px;
          @include cell_padding;
          border-bottom: 1px solid #006477;
          vertical-align: middle;

          img { vertical-align: middle; }
        }

        .question {
          @include font_left_th;
          border-right: 1px solid #006477;
          display: table-cell;
          background: #d1edf2;

          em {
            text-decoration: underline;
          }
        }

        .answer {
          @include font_regular;
          background: #fff;
          display: table-cell;
        }
      }

      li:last-child {
        border-bottom: none;
      }
    }
  }

  .item.topics {

    display: -webkit-flex;
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;

    h2 { width: 100% }

    ul {
      @include font_regular;
      width: 50%;
      margin-top: 15px;
      margin-bottom: 15px;
      box-sizing: border-box;

      li {
        margin-left: 15px;
        margin-right: 10px;
        box-sizing: border-box;
        margin-bottom: 8px;

        display: -webkit-flex;
        display: flex;


        &:before {
          content: '□';
          content:'';
          width: 8px;
          height: 15px;
          background-image: url('./white_square_u25A1_icon_256x256.png');
          background-size: contain;
          background-position: center top 4px;
          background-repeat: no-repeat;
        }

        p { padding-left: 5px; }

        // @media print {
        //   margin-bottom: 2px;
        // }
      }
    }
  }

  .item.when-pregnant {
    p {
      @include font_regular;
      padding: 10px;
    }
  }

  .item.notes {
    .note {
      padding: 14px 14px 7px 14px;
      @include font_regular;
      // @media print {
      //   padding-top: 10px;
      // }

      p {
        margin-top: 0px;
        margin-bottom: 9px;
        &:before {
          content: '»';
          margin-right: 6px;

        }
      }
    }

    .note.empty {
      font-size: 13px;
      line-height: 19px;
      font-style: italic;
      color: #808080;
    }
  }

  .methods {
    page-break-inside: avoid;

    h2 {
      font-style: italic;
      font-weight: bold;
      color: #006477;
      font-size: 14px;
    }

    ul {
      list-style: none;
      padding: 20px 0px 0px;
      margin: 0;
    }

    li {
      @include method-icon-actual-image-style();
      width: 80px;
      font-size: 13px;
      line-height: 15px;
      text-align: center;
      margin-right: 32px;
      margin-bottom: 20px;
      padding-top: 85px;
      display: inline-block;

      &:nth-child(6n) {
        margin-right: 0;
      }

      &:nth-child(6n + 1) {
        clear: both;
      }
    }

    .empty {
      padding: 10px 10px 10px 0px;
      margin: 0;
      font-size: 13px;
      line-height: 19px;
      font-style: italic;
      color: #404040;
      margin-bottom: 10px;
    }
  }

  .flex-container {

    display: -webkit-flex;
    display: flex;

    .item.red-flags, .item.important {
      box-sizing: border-box;
      width: calc(50% - 15px);
      clear: none;
    }
  }

  .item.important {
    // clear: left;

    ul li {
      .question {
        width: 60%;
        @include font_left_th;
      }
      .answer {
        width: 41%;
        text-align: center;
        line-height: 17px;
        min-height: 27px;

        img {
          display: block;
          margin: 1px auto;
        }
      }
    }
  }

  .item.red-flags {
    margin-left: 30px;

    .body {
      padding: 10px;
      @include font_regular;
      min-height: 63px;
    }
  }

  .item.side-effects {
    ul li {
      .question {
        width: 30%;
      }
      .answer {
        width: 70%;
      }
    }
  }

  table.item {
    width: 100%;
    border-spacing: 0;
    font-style: italic;
    font-size: 13px;
    border-collapse: collapse;
    border: none;
    table-layout: fixed;

    caption {
      font-size: 14px;
      line-height: 14px;
      color: #006477;
      margin-bottom: 5px;
      text-align: left;
      font-weight: bold;
    }

    thead th {
      @include font_h2;
      line-height: 1.3;
      background: #006477;
      color: #fff;
      @include cell_padding;
      text-align: center;
      border: 1px solid #006477;
    }

    td, tbody th {
      text-align: center;
      border: 1px solid #006477;
      @include cell_padding;
      width: 23.66%;
      line-height: 13px;
      color: #006477;
      vertical-align: middle;

      &:first-child {
        text-align: left;
        background: #d1edf2;
        width: 29%;
        @include font_left_th
      }
    }

    &.columns-4 {
      td {
        width: 17.5%;
        &:first-child { width: 29%; }
      }
    }
  }

  .summary-button-container, .path4you-button-container {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-shrink: 0;
    flex-shrink: 0;
    width: 100%;
    padding-bottom: 30px;
    margin-top: auto;

    > * { flex: 5; }
    .spacer { flex: 1; }

    #summary-main-menu, #summary-restart, .back-to-in-control {
      min-height: 45px;

      button {
        min-height: 45px;
      }
      
      p, span { 
        font-size: 24px;
      }
    }

    .spacer {
      height: 10px;
      min-width: 10px;
      // background: pink;
    }

    .back-to-in-control {
      min-width: 220px;

      span {
        margin: 5px;
        font-size: 20px;
      }
    }

    .summary-button {
      padding: 8px;
      min-width: 200px;
      max-width: 300px;
      box-sizing: border-box;
      color: white;
      font-style: italic;
      text-align: center;
      display: -webkit-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 24px;

      &.restart { background-color: $medium_blue; }
      &.main-menu { background-color: $link_green; }
      &.email, &.navigate {
        background-color: $dark_blue;
        margin-right: 0;
        margin-left: auto;
        cursor: pointer;
      }

      img {
        margin-right: 10px;
        height: 27px;
      }
    }

    .return-to-path4you {
      background-color: #852065;
    }
  }

  .path4you-button-container {
    .summary-button, .summary-button.email { margin: 20px auto 0; }
    
    .return-to-path4you {
      font-size: 22px;
      height: 43px;
      width: 250px;
    }
  }
}


@media only screen and (min-width: 720px) {
  .summary.mypath4you {
    .summary-button-container {
      justify-content: center;

      #summary-main-menu, #summary-restart, .back-to-in-control { margin: 0 40px; }
    }
  }

  .summary.inControl .summary-button-container .summary-button.navigate {
    width: 260px;
  }
}

@media only screen and (max-width: 719px){
  .summary {
    .print-button.regular { display: none }
    .print-button.mobile { display: block }

    .summary-heading {
      .heading-container h1, .user-id { font-size: 18px;}
      .heading-container { padding: 15px 0 12px;}
      .user-id { padding: 19px 0 16px;}
    }

    .description, #mypath_description, #mypath_postscript, .path4you-study-info {
      font-size: 16px;
      line-height: 22px;

      strong {
        font-size: 15px;
        font-weight: bold;
      }

      ol { padding: 0 16px; }
    }

    .summary-container { width: 90vw; }

    table.item {
      th { font-size: 12px; }

      td:first-child { font-size: 12px; }
    }

    .item.topics {
      padding-bottom: 6px;

      h2 { margin-bottom: 6px; }

      ul {
        margin: 0;
        width: 100%;

        li { margin: 2px 10px}
      }
    }

    .flex-container {
      display: -webkit-flex;
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;

      .item.important.questions,
      .item.red-flags {
        width: 100%;
        margin-left: 0;

        .body { min-height: 0}
      }
    }

    .summary-button-container {
      -webkit-flex-direction: column;
      flex-direction: column;
      padding-bottom: 0;

      > * { flex: unset; }
      .back-to-in-control { 
        background: #393653;
      }

      .summary-button, #summary-main-menu, #summary-restart, .back-to-in-control {
        min-width: 300px;
        margin: 0 auto 20px;
      }

      .summary-button.email { margin-right: auto }
    }

    .modal {
      .modal-content {
        input::placeholder, p { @include mobile-paragraph-style; }
      }
    }

    &.es table.check-table {
      td, th { padding: 5px; }
    }
  }
}

.es.summary #summary-restart button p {
  max-width: 140px;
}

@media only screen and (max-width: 500px){
  .summary table.check-table.item {
    tbody td, thead th, tbody th {
      padding: 5px;
      font-size: 12px;
    }
  }
}

@media only screen and (max-width: 374px){
  .summary table.check-table.item {
    tbody td, thead th, tbody th {
      padding: 5px 1px;
      font-size: 11px;
    }
  }
}

@media print {
  .summary.background-image {
    min-height: auto;
    padding: 0;
    // avoid desktop Safari from taking up more space than there is
    max-width: 1160px;
    // avoids table truncating on firefox and table splitting in Chrome
    display: block;

    * {line-height: 1 !important;}

    .summary-heading {
      .user-id { display: block; }
    }

    .summary-container{
      page-break-after: avoid;

      .item {
        margin-bottom: 15px;

        &:last-of-type { page-break-after: avoid; }
      }
    }

    // .methods ul { padding-top: 5px}

    .summary-button-container {
      display: none;
      // background-color: pink;
    }

    .print-button { display: none }

    &.firefox {
      .item {
        width: 100%;

        th, h2, ul li div {
          background-color: white;
        }

        h2 { border-bottom: 2px solid $dark_blue}
      }
    }
  }
}
