@import '../../../scss/mixins';
@import '../../../scss/colors';

.considerations {
  .icon-container {
    @include flexbox-icon-container;
    width: 100%;
    box-sizing: border-box;
    padding: 0;
    margin: 0px auto;
    margin-bottom: 20px;
    max-width: 720px;

    .icon-button {
      @include flexbox-icon;
      font-size: 16px;
      margin: 10px 15px 25px 15px;
      width: 115px;
      height: 152px;
      background-color: white;
      box-sizing: border-box;
      position: relative;
      @include method-icon-actual-image-style();

    }
  }

  .modal {
    padding: 20px 20px 10px 20px;
    width: 93.5vw;
    max-width: 800px;

    .modal-content {
      .modal-heading-container {
        margin-bottom: 10px; 

        h1 { 
          @include modal-green-title; 
          padding: 0;
          margin: 0;
        }
        
        .heading-background {
          background-repeat: no-repeat;
          background-position: left center;
          height: 27px;
          width: 27px;
          background-size: contain;
          display: inline-block;
          position: relative;
          top: 4px;
          margin-right: 8px;
          
          &.sterilization {background-image: url('./sterilization.png')}
          &.hormonalIud {background-image: url('./iud.png')}
          &.copperIud {background-image: url('./iud.png')}
          &.implant {background-image: url('./implant.png')}
          &.shot {background-image: url('./shot.png')}
          &.ring {background-image: url('./ring.png')}
          &.patch {background-image: url('./patch.png')}
          &.pill {background-image: url('./pill.png')}
          &.diaphragm {background-image: url('./diaphragm.png')}
          &.maleCondom {
            background-image: url('./male_condom.png');
            background-position-x: center;
          }
          &.femaleCondom {
            background-image: url('./female_condom.png');
            background-position-x: center;
          }
        }
      }

      .markdown-block ul {
        margin: 0 ;
        -webkit-padding-inline-start: 30px;
        padding-inline-start: 30px;

        li {  
          margin-bottom: 10px;
          font-size: 18px;
        }
      }
    }
  }
}

@media only screen and (max-width: 719px) {
  .considerations >.heading-container h1 { white-space: wrap; }
}

@media only screen and (max-width: 620px) {
  .considerations {
    .markdown-block p {@include mobile-paragraph-style;}
    

    .icon-container {  
      @include mobile-row-of-3-icon-container;
    }

    .overlay .modal {
      width: calc(100vw - 40px);
      margin-left: 20px;
      margin-right: 20px;

      .modal-content {
        .modal-heading-container .heading-background { 
          height: 20px;
          width: 20px;
        }

        .markdown-block  ul {
          -webkit-padding-inline-start: 20px;
          padding-inline-start: 20px;

          li { font-size: 16px; }
        }
      }
    }
  }
}

@media only screen and (min-width: 835px) {
  .considerations >.heading-container h1 { 
    white-space: pre-wrap;
    max-width: 760px;
  }
}

@media only screen and (min-width: 980px) {
  .considerations .icon-container {  max-width: none; }
}