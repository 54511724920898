@import '../../../scss/colors';

.background-image.main_nav_mp { 
  background-image: url('../../../shared_images/splash-mp-new-background.jpg');

  &.mypathPeripartum, &.mypathPeripartumVA, &.impact {
    .main-nav-buttons-container {
      margin-bottom: 240px;
    }
  }

  .main-nav-buttons-container {
    position: relative;
    margin-bottom: 120px;
  }
}

.accessible-colors.main_nav_mp {
  .main-nav-buttons-container .nav-button {
    $color-offset: 20px;
    color: black;
    padding-left: $color-offset; 
    
    &:nth-child(1) { background: linear-gradient(90deg, $orange $color-offset, #FFFFFF $color-offset);}
    &:nth-child(2) { background: linear-gradient(90deg, $dark_orange $color-offset, #FFFFFF $color-offset);}
    &:nth-child(3) { background: linear-gradient(90deg, $lighter_green $color-offset, #FFFFFF $color-offset);}
    &:nth-child(4) { background: linear-gradient(90deg, $dark_green $color-offset, #FFFFFF $color-offset);}
    &:nth-child(5) { background: linear-gradient(90deg, $light_blue $color-offset, #FFFFFF $color-offset);}
    &:nth-child(6) { background: linear-gradient(90deg, $dark_gray_blue $color-offset, #FFFFFF $color-offset);}

    &.visited {
      &:nth-child(1) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $orange $color-offset, #FFFFFF $color-offset);}
      &:nth-child(2) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $dark_orange $color-offset, #FFFFFF $color-offset);}
      &:nth-child(3) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $lighter_green $color-offset, #FFFFFF $color-offset);}
      &:nth-child(4) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $dark_green $color-offset, #FFFFFF $color-offset);}
      &:nth-child(5) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $light_blue $color-offset, #FFFFFF $color-offset);}
      &:nth-child(6) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $dark_gray_blue $color-offset, #FFFFFF $color-offset);}
      }
  }
}

@media only screen and (max-width: 374px){
  .accessible-colors.main_nav_mp {
    .main-nav-buttons-container .nav-button {
      $color-offset: 10px;
      color: black;
      padding-left: $color-offset; 
      
      &:nth-child(1) { background: linear-gradient(90deg, $orange $color-offset, #FFFFFF $color-offset);}
      &:nth-child(2) { background: linear-gradient(90deg, $dark_orange $color-offset, #FFFFFF $color-offset);}
      &:nth-child(3) { background: linear-gradient(90deg, $lighter_green $color-offset, #FFFFFF $color-offset);}
      &:nth-child(4) { background: linear-gradient(90deg, $dark_green $color-offset, #FFFFFF $color-offset);}
      &:nth-child(5) { background: linear-gradient(90deg, $light_blue $color-offset, #FFFFFF $color-offset);}
      &:nth-child(6) { background: linear-gradient(90deg, $dark_gray_blue $color-offset, #FFFFFF $color-offset);}
    
      &.visited {
        &:nth-child(1) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $orange $color-offset, #FFFFFF $color-offset);}
        &:nth-child(2) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $dark_orange $color-offset, #FFFFFF $color-offset);}
        &:nth-child(3) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $lighter_green $color-offset, #FFFFFF $color-offset);}
        &:nth-child(4) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $dark_green $color-offset, #FFFFFF $color-offset);}
        &:nth-child(5) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $light_blue $color-offset, #FFFFFF $color-offset);}
        &:nth-child(6) { background: url('../../../shared_images/narrow-hatching.png'), linear-gradient(90deg, $dark_gray_blue $color-offset, #FFFFFF $color-offset);}
        }
    }
  }
}
