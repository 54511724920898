@import '../../../scss/colors';
@import '../../../scss/mixins';

.important_information {
  .menu-container { 
    @include square-flexbox-menu-container;
    
    .menu-item { 
      @include flexbox-menu-item;
      width: 200px;
      height: 200px;
    }

    .menu-item-modal {
      padding: 20px 20px 0;
      .modal-content {
        &.first h1 { background-image: url('./when-you-can-start.svg')}
        &.third h1 { background-image: url('./blood-clot.svg')}
        &.fourth h1 { background-image: url('./breastfeeding.svg')}
      }
    }
  }

  .pdf-button-container {
    padding: 20px 0 30px;
    .pdf-button { 
      max-width: 380px;
      width: 90vw;
      color: white;
      background-color: $dark_green;
      padding: 14px 20px;

      p {margin: 0};
    }
  }

  #email-modal .modal { padding: 20px}
}


@media only screen and (max-width: 719px) {
  .important_information {
    .markdown-block p, .markdown-block ul { font-size: 16px; }

    .menu-container { 
      flex-direction:  column;
      margin-bottom: 0;

      .menu-item { margin-bottom: 20px;}

      .overlay .modal { @include mobile-full-screen-menu-item-modal }

    }
    
    .pdf-button-container { padding-top: 0 }
  }
}

@media only screen and (min-width: 835px) {
  .important_information .menu-container {  justify-content: space-evenly;  }
}