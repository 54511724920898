@import '../../../scss/colors';

.is-mypath.splash {
  background-image: url('../../../shared_images/splash-mp-new-background.jpg');
  background-position: center;
  flex:1 0 auto;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-justify-content: space-between;
  justify-content: space-between;

  %spacer {
    content: '';
    height: 1px;
    width: 100px;
  }
  
  .spacer-1 {
    @extend %spacer;
    -webkit-flex:1;
    flex: 1;
  }
  .spacer-2 {
    @extend %spacer;
    -webkit-flex: 0.35;
    flex: 0.35;
  }

  .flexbox-group {
    display: flex;
    display: -webkit-flex; 
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;
    height: 380px;
    position:relative;
    -webkit-flex: 0 0 auto;
    flex: 0 0 auto;

    .my-reproductive-health-journey {
      width: 633px;
      height: 380px;
      z-index: 1;
      background-size: contain;
      background-repeat: no-repeat;
    }

    .nav-buttons-container {
      margin-top: 0;
      
      display: -webkit-flex; 
      display: flex;
      -webkit-flex-direction: column;
      flex-direction: column;
      -webkit-justify-content: space-between;
      justify-content: space-between;
      -webkit-box-align: flex-end;
      -webkit-align-items: flex-end;
      align-items: flex-end;
      white-space: nowrap;
      position: relative;
      right: 0;
      width: 100%;
      bottom: 8px;
      padding-bottom:0;
      z-index: 10;
      padding-right: 17px;

      .nav-button {
        width: 200px;
        font-size: 24px;
        line-height: 2.5;
        background-color: $link_green;
      }
    }
  }
}

.mypathGeneral, .mypathVA, .mypathVAStudy, .mypath4you {
  &.splash .flexbox-group .my-reproductive-health-journey {
    background-image: url('./mypath.png');
  }
}

.mypathPeripartum.splash, .mypathPeripartumVA.splash, .impact.splash {
  .flexbox-group .my-reproductive-health-journey {
    background-image: url('./mypathPeripartum.png');
  }
}

// .mypath4you.splash 

@media only screen and (min-width: 835px){
  .is-mypath.splash {
    .spacer { flex: 0.25; }
  }
}

@media only screen and (max-width: 719px){
  .is-mypath.splash {
    .spacer-2 { flex: 1.4; }

    .flexbox-group {
      width: 100%;

      .my-reproductive-health-journey {
        width: 100%;
        height: 190px;
        left: 6px;
        max-width: 380px;
      }

      .nav-buttons-container{
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
        bottom: 12px;
        padding: 0;

        .nav-button { width: 247px; }
      }
    }
  }
}

.mbc-footer-mp {
  box-sizing: border-box;
  width: 90vw;
  max-width: 688px;
  height: 100px;
  
  display: -webkit-flex; 
  display: flex;
  -webkit-justify-content: space-between;
  justify-content: space-between;
  -webkit-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0;
  justify-self: flex-end;
  background-color: transparent;
  // margin-bottom: 20px;
  -webkit-flex-shrink: 0;
  flex-shrink: 0;


  .uw, .ucsf {
    height: 70%;
    position: relative;
    bottom: 6px;
    margin-right: 0;
  }

  .va {
    height: 100%;
  }
}

@media only screen and (min-width: 835px){
  .mbc-footer-mp { margin-bottom: 25px;}
}

@media only screen and (max-width: 719px){
  .mbc-footer-mp { 
    height: 15vw;
    width: 95vw;
  }
}