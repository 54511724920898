@import '../../scss/colors';
@import '../../scss/mixins';
@import '../../scss/dimensions';

.icon_array_page {
  .bc-bars-and-brackets-container, .bc-bars-and-brackets-mobile-container {
    display: -webkit-flex; 
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-justify-content: center;
    justify-content: center;

    .brackets { 
      display: inline-block; 

      span {
        font-family: "Archer SSm A", "Archer SSm B";
        font-weight: 700;
        font-style: italic;
      };
    }

    .bc-bar-items-container {
      display: -webkit-flex; 
      display: flex;
      -webkit-flex-shrink: 0;
      flex-shrink: 0;

      .bc-bar-item {
        height: $method-box-width;
        width: $method-box-width;
        border-left: white dotted 1px;
        background-size: contain;
        box-sizing: border-box;

        div.screen-reader-only { font-size: 2px;}
      }
    }

    .bc-result-container {
      background-color: $dark_blue;
      color: white;
      position: relative;
      text-align: left;
      box-sizing: border-box;
      padding: 7px 10px;

      @keyframes slideInFromRight{
        from { left: 100vw }
        to {left: 0 }
      }

      .selected p{
        animation-name: slideInFromRight;
        position: relative;
        display: inline;
        animation-duration: 0.5s;
        animation-timing-function: linear;
        overflow: hidden;
        font-size: 13px;
        
        span {font-style: italic }
      }
    }
  }
  
  .bc-bars-and-brackets-mobile-container {
    width: 100%;
    display: -webkit-flex; 
    display: flex;
    -webkit-flex-direction: column;
    flex-direction: column;
    -webkit-align-items: center;
    -webkit-box-align: center;
    align-items: center;

    .brackets { flex-shrink: 0; }

    .bc-bar-items-container {
      flex-shrink: 0;
      width: 100%;
      height: initial;

      .bc-bar-item:first-of-type { border: none}
    }
  }

  .mobile-graphics-container {
    .overlay .modal {
      margin: auto 20px;
      padding: 20px 10px 15px 10px;
      width: auto;

      .heading-container {
        padding: 0 5px;
        margin-bottom: 9px; 

        h1 { 
          font-size: 18px; 
          line-height: 1.2;
        }
      }

      .markdown-block {
        padding: 0 5px;

        p { 
          font-size: 14px;
          line-height: 1.4;
          margin-bottom: 12px; 
        }
      }
    }
  }
}

@media only screen and (max-width: 719px){
  .icon_array_page {
    .heading-container { @include mobile-heading-style; }

    .mobile-graphics-container .overlay {
      z-index: 600;

      .modal {
        max-width: 400px; 

        p:first-letter { text-transform:capitalize;}

        .egg-array-container {
          padding: 0;

          .egg-icon { background-size: 100%;}
        }
      }
    }
  }
}

@media only screen and (max-width: 650px){
  .icon_array_page {
    .markdown-block p { 
      @include mobile-paragraph-style;
      margin-bottom: 5px;
    }
  }
}

@media only screen and (max-width: 450px){
  .icon_array_page .heading-container{
    padding-right: 10%;
  }
}

@media only screen and (max-width: 360px){
  .icon_array_page .mobile-graphics-container .overlay .modal {
    margin: auto 15px;
  }
}