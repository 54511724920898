@import '../../../scss/colors';
@import '../../../scss/mixins';

.intro.peripartum.background-image {
  background-image: url('../../mybc/splashMBC/hello-beautiful-50-background.jpg');
  background-position: left center;
  background-size: cover;
  
  display: -webkit-flex; 
  display: flex;
  width: auto;
  margin: 0;
  -webkit-flex-direction: column;
  flex-direction: column;
  -webkit-justify-content: space-between;
  justify-content: space-between;

  .aria {
    &:before {
      width: 0;
      height: 0;
      
    }
    &:after  {
      width: 0;
      height: 0;
      
    }
    flex-direction: column;
    align-items: center;

    .flex-group {
      >.markdown-block {
        background: rgba(255,255,255,0.8);
        border-radius: 8px;
        padding: 17px 20px;
        margin: 27px 0 30px;
      }

      .heading-container { 
        @include long-h1-heading;
        padding: 30px 0 0;
        margin-bottom: 0;
        display: flex;
        flex-direction: column; 
        align-items: center;
        min-height: 0;
        
        h1{
          display: inline-block;
          background-color: $dark-blue;
          color: white;
          padding: 1px 8px;
          margin-bottom: 4px;
          font-size: 3.1vw;

        }
      }

      p:last-of-type { margin: 0 }

    }
  }

  

  .overlay .modal {
    padding: 20px 20px 0;   

    .modal-content {  
      width: 100%; 

      h1 {
        @include archer;
        font-size: 24px;
        margin-bottom: 10px;
      }
    }
  }
}

@media only screen and (max-width: 719px){
  .intro.peripartum {
    .aria .flex-group {
      .heading-container {
        padding-top: 16px;
        
        h1:after, h1:before { 
          width: 0; 
          height: 0;
          content:none;
        }
      }

      .markdown-block {
        margin-top: 16px; 
        padding: 18px 20px;

        p { 
          font-size: 16px;
          margin-bottom: 16px;
          &:last-of-type { margin: 0 }
        }
      }
    }
  

    .overlay .modal {
      @include mobile-modal-style;
    }
  }
}

@media only screen and (max-width: 515px){
  .intro.peripartum .aria .flex-group {
    .heading-container {
      padding-right: 8px;
      display: block;
      text-align: center;
      font-size: 0;

      h1{
        font-size: 16px;
        display: inline;

        &:first-of-type { padding-right: 0}
        &::last-of-type { padding-left: 0 }
      }
    }
  }

  .intro.peripartum.es .aria .flex-group .heading-container h1 { display: inline-block}
}

@media only screen and (max-width: 374px){
  .intro.peripartum .aria .flex-group .heading-container h1 {
    font-size: 13px;
  }
}

@media only screen and (min-width: 835px){
  .background-image.intro.peripartum {
    .nav-buttons-container {
      padding-right: 5vw;
      padding-left: 5vw;
    }
   }
}

@media only screen and (min-width: 900px){
  .background-image.intro.peripartum { 

    .aria .flex-group .heading-container {
      h1 { font-size: 27px; }
    }
  }
}

@media only screen and (min-width: 1000px){
  .background-image.intro.peripartum { 
    padding-left: calc((100vw - 900px) / 2);
    padding-right: calc((100vw - 900px) / 2);
  }
}