@import '../../../scss/mixins';
@import '../../../scss/colors';

$icon-graphic-width: 90px;
$icon-extra-width-for-text: 12px;
$icon-total-width: $icon-graphic-width + 2 * $icon-extra-width-for-text;

.compare_methods {
  position: relative;
  // background-color: lightblue;

  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
  flex-direction: column;
  width: 90vw;

  .rainbow { display: none;}

  .nav-buttons-container {
    flex-direction: row;

    .nav-button { line-height: 38px; }

    .regular.fake-ribbon {
      width: auto;
      padding: 0 20px;
      justify-self: flex-start;
      font-size: 20px;
    }
  }

  .mobile, .fake { display: none }

  .markdown-block p { margin-bottom: 20px; }

  .overlay .modal {
    min-width: 650px;
    width: 92vw;
    max-width: 708px;
    padding: 20px;

    .heading-container {
      padding-top: 0px;

      h1 {
        color: $light_green;
        font-size: 24px;
      }
    }

    .modal-content {
      max-height: calc(100vh - 54px);
      overflow-y: auto;

      .rec-section-container {

        display: -webkit-flex;
        display: flex;
        -webkit-flex-wrap: wrap;
        flex-wrap: wrap;
        -webkit-justify-content: center;
        justify-content: center;

        .rec-icon-container {

          display: -webkit-flex;
          display: flex;
          -webkit-flex-direction: column;
          flex-direction: column;
          -webkit-align-items: center;
          -webkit-box-align: center;
          align-items: center;
          width: $icon-total-width;
          margin-left: calc((90vw - 70px - #{($icon-total-width) * 4}) / 8);
          margin-right: calc((90vw - 70px - #{($icon-total-width) * 4}) / 8);
          margin-bottom: 10px;
          // background-color: yellow;

          p, label {
            font-size: 16px;
            line-height: 19px;
            white-space: pre-wrap;
            margin-bottom: 0;
            text-align: center;

            .stars-container {

              display: -webkit-flex;
              display: flex;
              margin-top: 5px;
              -webkit-justify-content: center;
              justify-content: center;

              .star { margin: 0 2.5px}
            }
          }

          .icon {
            $height-extension: 7px;
            height: $icon-graphic-width + $height-extension;
            width: $icon-graphic-width;
            padding-bottom: $height-extension;
            background-color: white;
            @include method-icon-actual-image-style();
          }
        }
      }

      .star-legend-container {
        $star-legend-font-size: 13px;
        $star-legend-font-increase-for-lineheight: 3px;

        background-color: $x_light_gray;
        border: 1px dotted $medium_gray;
        padding: 13px 13px 13px - ($star-legend-font-size - $star-legend-font-increase-for-lineheight) 13px;
        margin-top: 5px;
        font-size: $star-legend-font-size;

        .star-legend-heading{
          // color: purple;
          font-weight:bold;
          font-style: italic;
          margin-bottom: $star-legend-font-size;
          line-height: 1.2;
        }

        .list-item {

          display: -webkit-flex;
          display: flex;

          &:nth-of-type(1):before { color: $dark_blue}
          &:nth-of-type(2):before { color: $light_green}
          &:nth-of-type(3):before { color: $dark_orange}

          &:before {
            content: "★";
            font-size: 1.4 * $star-legend-font-size;
            line-height: $star-legend-font-size;
            margin-right: 2px;
          }


          p {
            font-size: $star-legend-font-size;
            margin-bottom: $star-legend-font-size - $star-legend-font-increase-for-lineheight;
            line-height: $star-legend-font-size + $star-legend-font-increase-for-lineheight

          }
        }
      }
    }
  }

  .compare-table {
    $cell-spacing: 11px;
    margin-bottom: 20px;
    align-self: center;
    -webkit-flex:1 0 auto;
    flex: 1 0 auto;
    width: 100%;
    width: calc(100% + #{$cell-spacing * 2});
    // background-color: pink;
    font-style: italic;
    border-collapse: separate;
    border-spacing: $cell-spacing 0;

    %dashed-border {border-bottom: 1px dashed $medium_gray}
    %table-content-font-style {
      font-size: 13px;
      line-height: 18px;
    }
    %common-cell-spacing-style {
      padding-top: $cell-spacing;
      padding-bottom: $cell-spacing;
      box-sizing: border-box;
    }

    %common-cell-border-style {
      border-left: 1px dotted $medium_gray;
      border-right: 1px dotted $medium_gray;
    }

    .table-row {
      white-space: pre-wrap;
      text-align: right;
      height: 101px;
      // background-color: yellow;
      @extend %common-cell-spacing-style;
      @extend %dashed-border;
      @extend %table-content-font-style;

      &#benefits, &#who-shouldn\'t-use-this-method { height: 121px;  }

    }

    #blank {
      color: transparent;
      text-align: right;
      @extend %table-content-font-style;
    }

    .table-td {
      background-color: $x_light_gray;
      // background-color: orange;
      box-sizing: border-box;
      width: 39%;
      height: 101px;
      padding-left: 10px;
      padding-right: 10px;
      position: relative;
      @extend %common-cell-spacing-style;
      @extend %table-content-font-style;
      @extend %common-cell-border-style;

      &.active {
        @extend %dashed-border;

      }

      ul {
        list-style: disc outside none;
        margin-left: 27px;

        li { padding-left: 9px;}
      }
    }

    .table-tab {
      padding: 0 9px 0 5px;
      text-align: left;
      vertical-align: middle;
      position: relative;
      height: 60px;
      @extend %dashed-border;
      &:nth-of-type(2), &:nth-of-type(3) {
        @extend %common-cell-border-style;
        border-top: 1px dotted $medium_gray;
      }
      &.inactive {
        background-color: $x_light_gray;
      }
      &.active {
        background-color: $dark_blue;
        border-bottom: 1px solid $medium_gray;
      }

      .active-tab-button{
        background-image: url('./thick-close-icon@2x.png');
        background-color: $dark_blue;
        background-position: right 2px center;
        background-repeat: no-repeat;
        background-size: auto 16px;
        width: 100%;

        .active-tab-paragraph {

          display: -webkit-flex;
          display: flex;
          -webkit-align-items: center;
          -webkit-box-align: center;
          align-items: center;
          color: white;
          font-size: 16px;
          font-style: italic;
          padding-left: 40px;
          background-size: 40px;
          line-height: 16px;
          background-repeat: no-repeat;
          max-width: 65%;
          min-height: 40px;
          vertical-align: middle;
          &.femaleCondom { background-image: url('../../../shared_images/illustrated_method_icons/internal-condom_blue.jpg');}
          &.maleCondom { background-image: url('../../../shared_images/illustrated_method_icons/male-condom_blue.jpg');}
          &.diaphragm { background-image: url('../../../shared_images/illustrated_method_icons/diaphragm_blue.jpg');}
          &.pill { background-image: url('../../../shared_images/illustrated_method_icons/pill_blue.jpg');}
          &.patch { background-image: url('../../../shared_images/illustrated_method_icons/patch_blue.jpg');}
          &.ring { background-image: url('../../../shared_images/illustrated_method_icons/ring_blue.jpg');}
          &.shot { background-image: url('../../../shared_images/illustrated_method_icons/shot_blue.jpg');}
          &.implant { background-image: url('../../../shared_images/illustrated_method_icons/implant_blue.jpg');}
          &.hormonalIud, &.copperIud { background-image: url('../../../shared_images/illustrated_method_icons/iud_blue.jpg');}
          &.sterilization { background-image: url('../../../shared_images/illustrated_method_icons/sterilization_blue.jpg');}
        }
      }

        .close-x {
          height: 0;
          font-size: 0;
          padding: 8px;
          float: right;
          position: absolute;
          top: 50%;
          right: 11px;
          transform: translateY(-8px);
          background-image: url('./thick-close-icon@2x.png');
          background-color: transparent;
          background-size: contain;
        }

        .select-button {
          color: $dark_blue;
          font-style: italic;
          font-size: 18px;
          line-height: 16px;
          background-color: $x_light_gray;
          padding: 5px;
        }
      }


    tr:last-of-type {
      .table-td {
        border-bottom: 1px dotted $medium_gray;
      }

      .table-row{
        border-bottom: none;
      }
    }
  }
  // &.es .regular.ribbon { font-size: 12px;}
}
@media only screen and (min-width: 769px){
  .compare_methods {
    .overlay .modal .modal-content {
      .rec-section-container .rec-icon-container {
        margin-left: 21px;
        margin-right: 21px;
      }
    }
  }
}

@media only screen and (min-width: 835px){
  .compare_methods {
    .overlay .modal {
      .modal-content .heading-container h1 { margin-top: 0 }
    }

    .compare-table {
      .table-row, .table-td { height: 71px;}

      .table-tab {
        height: 60px;
      }
    }
  }
}

@media only screen and (max-width: 719px){
  .compare_methods {
    .heading-container h1 { font-size: 3.5vw }

    .ribbon.regular { top: 25px; }

    .overlay .modal { min-width: 0 }

    .compare-table.regular .table-tab .select-button {font-size: 2.5vw}
  }
}

@media only screen and (max-width: 599px){
  .compare_methods{
    .regular { display: none; }
    .mobile { display : block; }

    .heading-container {
      @include mobile-heading-style;
    }

    .markdown-block p {
      @include mobile-paragraph-style;
    }

    .overlay {
      background-color: white;

      .modal {
        border-radius: 0;
        width: 100vw;
        height: 100%;
        margin-bottom: 0;
        margin-top: 0;
        padding: 0;

        .rainbow {
          margin-bottom: 16px;
          display: block;
        }

        .close-x { display: none; }

        .modal-content {
          padding: 0 5vw;
          overflow-x: hidden;
          max-height: none;
          background-color: white;

          .heading-container {
            padding-bottom: 2px;

            h1 {
              color: $dark_blue;
              font-size: 18px;
            }
          }

          .nav-buttons-container.fake {

            display: -webkit-flex;
            display: flex;
            -webkit-flex-direction: row;
            flex-direction: row;
            padding: 20px 0 30px 0;
          }

          .rec-section-container {
            -webkit-justify-content: flex-start;
            justify-content: flex-start;
            margin-left: calc((90vw - 3 * #{$icon-graphic-width} ) / (-4));
            margin-right: calc((90vw - 3 * #{$icon-graphic-width} ) / (-4));

            .rec-icon-container {
              width: $icon-graphic-width;
              margin-left: calc((90vw - 3 * #{$icon-graphic-width} ) / (4));
              margin-right: calc((90vw - 3 * #{$icon-graphic-width} ) / (4));

              p { font-size: 13px; }
            }
          }
        }
      }
    }

    .compare-table {
      width: 100%;
      border-collapse: collapse;

      tr{
        &.inactive { display: none }
        &:last-of-type .table-td { border-bottom: 0px; }
      }

      .table-tab {
        border: 1px dotted $medium_gray;
        padding: 0 4px;
        height: 50px;
        width: 50vw;
        &.active {
          .active-tab-paragraph {
            background-image: none;
            padding-left: 36px;

          }
        }


        .select-button {
          font-size: 16px;
          top: 0;
        }
      }

      .table-row {
        background-color: $dark_blue;
        color: white;
        white-space: normal;
        text-align: center;
        padding: 6px 0;
        height: auto;
        border-right: 1px solid $dark_blue;
        border-bottom: 0;
        &#benefits, &#who-shouldn\'t-use-this-method { height: auto;  }
      }

      .table-td {
        height: 66px;
        background-color: transparent;
        border: 0px;
        &.active {border: 0px;}
        &:last-of-type { border-left: 1px dotted $medium_gray}

        ul li { padding-left: 0}
      }
    }

    .nav-buttons-container {

      .fake-ribbon {
        width: 100%;
        font-size: 20px;
        z-index: auto;
      }
    }

    &.es {
      .nav-buttons-container .nav-button.fake-ribbon {
        font-size: 16px;
        line-height: 40px;
      }

      .compare-table.mobile .table-td {
        ul {
          margin-left: 15px;

          li { padding-left: 0;}
        }
      }
    }
  }
}

@media only screen and (max-width: 360px){
  .compare_methods .overlay .modal .modal-content .rec-section-container {
    $small-icpn-graphic-width: 80px;

    margin-left: calc((90vw - 3 * #{$small-icpn-graphic-width} ) / (-4));
    margin-right: calc((90vw - 3 * #{$small-icpn-graphic-width} ) / (-4));

    .rec-icon-container {
      width: $small-icpn-graphic-width;
      margin-left: calc((90vw - 3 * #{$small-icpn-graphic-width} ) / (4));
      margin-right: calc((90vw - 3 * #{$small-icpn-graphic-width} ) / (4));
      margin-bottom: 5px;

      .icon {width: $small-icpn-graphic-width; }

      p {
        height: 58px;

        display: -webkit-flex;
        display: flex;
        -webkit-flex-direction: column;
        flex-direction: column;
        -webkit-align-items: center;
        -webkit-box-align: center;
        align-items: center;
        -webkit-justify-content: space-between;
        justify-content: space-between;
      }
    }
  }
}
