.fertility_survey_mp {

  .background-container {
    flex-grow: 1;
    margin-bottom: 20px;
    position: relative;

    @media only screen and (min-width: 719px){
      background-image: url('./MomKiss.png');
      background-position: left center;
      background-repeat: no-repeat;
      background-size: 275px 275px;
    }

    @media only screen and (min-width: 1000px){
      background-size: 350px 350px;
    }
  }

  .survey-questions-container .survey-question-container {
    justify-content: flex-end;

    @media only screen and (min-width: 719px){
      .survey-choices-container {
        margin-top: 30px;
      }
    }
  }

  .nav-buttons-container {
    margin-top: 0;
  }
}
