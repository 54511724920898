.overlay {
  height: 100%;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.6);
  
  display: -webkit-flex; 
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-align-items: center;
  -webkit-box-align: center;
  align-items: center;
  overflow: scroll;
  z-index: 200;
  -webkit-flex-direction: column;
  flex-direction: column;

  .modal {
    background-color: white;
    width: 500px;
    box-sizing: border-box;
    border-radius: 6px;
    position: relative;
    margin: auto 0px;

    button.close-x {
      //make a transparent X
      background-image: url('./close-icon.png');
      background-color: transparent;
      background-size: contain;
      border: none;
      color: transparent;
      //positioning & sizing
      font-size: 0;
      position: absolute;
      right: -7px;
      top: -7px;
      padding: 15px;
    }

    .modal-content {
      display: inline-block;

      // p {
      //   font-size: 20px;
      //   line-height: 1.3;
      // }
    }
  }
}
